var $q = window.Q;
var notify = window.notify;
var _ = window._;
var formWatch = window.formWatch;

$(function () {
    
    /*
     * Compile elements
     */
    formWatch.start($('form.watch-changes'));

    /*
     * Bind events
     */
    /*
        Form submit event handler, to check the ff:
        - to ask confirmation
        - block form on submit
     */
    $('form').submit(function (e) {
        e.preventDefault();
        var form = this;

        // fallback alert when submit button fails to disable
        if ($(form).hasClass('watch-changes') && !formWatch.isFormDirty($(this))) {
            alert('Nothing to save!'); 
            e.preventDefault();
            return;
        }

        var promise = $q.when();

        if (!_.isUndefined($(this).data('confirm'))) {
            var msg = $(this).data('confirm') || 'Are you sure?';
            promise = notify.confirm(msg);
        }

        promise.then(function () {
            $(form).addClass('submitting');

            if (!_.isUndefined($(form).data('block-on-submit'))) {
                notify.block($(form).data('block-on-submit') || 'Saving... Please wait!');
            }
            form.submit();
        });
    });

    window.onbeforeunload = function () {
        var dirtyForms = $('form.watch-changes').filter(function () {
            return !$(this).hasClass('submitting') && formWatch.isFormDirty($(this));
        });

        if (dirtyForms.length) {
            return "You have unsaved changes. Are you sure to leave this page?";
        }
    };
});
