var fn = window.fn = {};

fn.isNumber = function (val) {
    if (val == '' || isNaN(val)) {
        return false;
    }

    return window._.isNumber(val);
};

fn.isNumeric = function (val) {
    if (val == '' || isNaN(val)) {
        return false;
    }

    return window._.isNumber(val.toFloat());
};
