window.FormShortcuts = function FormShortcuts (scope) {
  var self = this;
  self.scope = scope;
  self.hotkeys = {
    save: 'ctrl+s',
    delete: 'ctrl+del',
    duplicate: 'alt+d',
    new: 'alt+n',
    post: 'alt+p',
    revert: 'alt+r',
  };

  /**
   * Find button by a given key action.
   *
   * @param  {string} keyAction
   * @return {object}
   */
  self.findBtn = function (keyAction) {
    var selector = '.'+keyAction+'-btn';
    return $(self.scope).find(selector);
  }

  /**
   * Get existing hotkeys in the scope or form.
   * @return {object}
   */
  self.getHotkeys = function () {
    var hotkeys = {};

    Object.keys(self.hotkeys).map(function (key) {
      // button is found
      if (self.findBtn(key).length) {
        hotkeys[key] = self.hotkeys[key];
      }
    });

    return hotkeys;
  };

  /**
   * Get hotkeys function bindings.
   *
   * @return {array}
   */
  self.getBindings = function () {
    var bindings = [];

    Object.keys(self.getHotkeys()).map(function (key) {
      var hotkey = self.hotkeys[key];
      var btn = self.findBtn(key);

      bindings.push({
        hotkey: hotkey,
        fn: function () {
          // event bindings
          if (btn.hasClass('disabled') || btn.is(':disabled')) {
            return false;
          }

          if (btn.is('a')) {
            btn[0].click();
            return false;
          }

          btn.click();

          return false;
        }
      })
    });

    return bindings;
  };

  /**
   * Set button titles with shortcut key.
   */
  self.setButtonTitles = function () {
    Object.keys(self.getHotkeys()).map(function (key) {
      var btn = self.findBtn(key);
      var title = btn.attr('title') || '';
      var hotkey = self.hotkeys[key];
      btn.attr('title', title+ ' (' +hotkey.toUpperCase()+')');
    });
  };

  /**
   * Bind the event bindings for the buttons.
   *
   * @return {void}
   */
  self.bind = function (targets) {
    (new window.Hotkeys(
      self.getBindings(),
      targets || [document, 'input, textarea, select']
    )).bind();
  };
};

$(function () {
  $('.form-shortcuts').each(function () {
    var fs = (new FormShortcuts(this));
    fs.setButtonTitles();
    fs.bind();
    if (!!$(this).data('save-form-shortcuts')) {
      $(this).data('form-shortcuts', fs);
    }
  });
});
