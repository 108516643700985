var $      = window.jQuery;

$(function () {
    $('.table-action-btn').each(function () {
        $(this).attr('disabled', 'disabled');
    });

    var $checkboxes = $('.table-action-btn').closest('.box').find('.box-body table input.icheck');

    $checkboxes.on('ifToggled', function () {
        var $btn = $(this).closest('.box').find('.table-action-btn');
        if (filterChecked($checkboxes).length > 0) {
            $btn.removeAttr('disabled');
        } else {
            $btn.attr('disabled', 'disabled');
        }
    });

    function filterChecked($checkboxes) {
        return $checkboxes.filter(function () {
            return $(this).is(':checked');
        });
    }
});
