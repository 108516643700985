require('./vendor.js');

///////////////////////
// Application Files //
///////////////////////
window.notify = require('./notify.js');
window.formWatch = require('./form_watch.js');

require('./fn.js');
require('./number_extensions.js');
require('./string_extensions.js');
require('./icheck.js')
require('./sidebar_state.js');
require('./control_sidebar.js');
require('./form.js');
require('./table_action_btn.js');
require('./table_delete.js');
require('./table_select_all.js');
require('./hotkeys.js');
require('./form-shortcuts.js');
