window.Hotkeys = (function () {
  function Hotkeys(collection, targets) {
    var self = this;
    self.collection = collection;
    self.targets = targets;

    self.bind = function () {
      self.collection.forEach(function (h) {
        self.targets.forEach(function (target) {
          $(target).bind('keydown', h.key||h.hotkey, h.fn);
        });
      });
    };

    self.bindAll = function () {
      self.bind();
    }
  };

  return Hotkeys;
})();