/*
 * Function services
 */
/**
 * Start binding watch behaviour on form.
 *
 * @param  {string|Object} form Selector or jquery object element
 * @return {void}
 */
function start (form) {
    $(form).addClass('watch-changes');

    // Save original value for dirty checking of inputs
    $(form).find('input.form-control, textarea.form-control, select').each(function () {
        $(this).data('original-value', $(this).val());
    });

    // Save original checked state of checkboxes
    $(form).find('input[type=checkbox]').each(function () {
        $(this).data('original-value', $(this).is(':checked'));
    });

    // Disable submit button initially
    $(form).find('button[type=submit]').not('.no-disable').attr('disabled', 'disabled');

    // Enable/disable submit button if the form becomes dirty on input interactions
    // ifToggled is a iCheck event
    // exclude input[type=date], which cause unexpected behaviour on input[type=date] elements
    $(form).find('input, textarea, select, :checkbox').not('input[type=date], .dont-watch').on('change input keyup blur ifToggled', function () {
        if ($(this).val() && $(this).val().toString().trim() == '') {
            $(this).val('');
        }

        if (isFormDirty($(this).closest('form'))) {
            $(this).parents('form').find('button[type=submit]').removeAttr('disabled');
        } else {
            $(this).parents('form').find('button[type=submit]').attr('disabled', 'disabled');
        }
    });
    // Separate bind for input type date
    $(form).find('input[type=date]').on('blur keyup', function () {
        if (isFormDirty($(this).closest('form'))) {
            $(this).parents('form').find('button[type=submit]').removeAttr('disabled');
        } else {
            $(this).parents('form').find('button[type=submit]').attr('disabled', 'disabled');
        }
    });
}

function forceDirty(form) {
    $(form).addClass('force-dirty');
    $(form).find('button[type=submit]').removeAttr('disabled');
}

/**
 * Is form dirty.
 *
 * @param  {string|Object}  form Selector or jquery object element
 * @return {Boolean}
 */
function isFormDirty(form) {
    if ($(form).hasClass('force-dirty')) {
        return true;
    }

    var dirty = false;
    $(form).find('input.form-control, textarea.form-control, select, input[type=checkbox]')
    .each(function () {
        if (isInputDirty(this)) {
            dirty = true;
        }
    });
    return dirty;
}

/**
 * Is input dirty.
 *
 * @param  {string|Object}  form Selector or jquery object element
 * @return {Boolean}
 */
function isInputDirty(input) {
    // for checkbox
    if ($(input).attr('type') == 'checkbox') {
        return $(input).data('original-value') != $(input).is(':checked');
    }

    // for multiple select
    if (_.isArray($(input).val())) {
        return $(input).data('original-value').toString() != $(input).val();
    }

    return $(input).data('original-value') != $(input).val();
}

module.exports = {
    start: start,
    isFormDirty: isFormDirty,
    isInputDirty: isInputDirty,
    forceDirty: forceDirty
};