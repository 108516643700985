var notify = window.notify;
var _      = window._;
var $q     = window.Q;
var axios  = window.axios;

$(function () {
    $('.table-delete-btn').click(function (e) {
        var promise = $q.when();
        if (!_.isUndefined($(this).data('confirm'))) {
            promise = notify.confirm($(this).data('confirm') || 'Are you sure to delete selected item(s)?')
        }

        promise.then(deleteFn);
    });

    function deleteFn (promise) {
        notify.block('Deleting... Please Wait!');
        
        setTimeout(function () {
            window.location.replace('?message=Successfully Deleted!&alert_icon=fa-check&alert_class=alert-success&message_details');
        }, 1000);
    }
});