const $q = require('q');
let notify = {};

function classId(msg) {
    return msg.trim().replace(/\W/g, '').toLowerCase();
}

function _launch(callback) {
    callback();
    // setTimeout(function () {
    //     callback();
    //     console.log('launched');
    // });
}

notify.info = function (msg, options) {
    _launch(function() {
        if ($('.info-'+classId(msg)).length) {
            return;
        }

        options = $.extend({
            header: '<i class="fa fa-info-circle"></i> INFORMATION',
            /*sticky: true ,*/
            life: 10000,
            theme: 'bg-aqua bg-solid jgrowl-box-shadow info-'+classId(msg)
        }, options);

        $.jGrowl(msg, options);
    });
};

notify.warning = function (msg, options) {
    _launch(function() {
        if ($('.warning-'+classId(msg)).length) {
            return;
        }

        options = $.extend({
            header: '<i class="fa fa-warning"></i> WARNING',
            /*sticky: true ,*/
            life: 10000,
            theme: 'bg-yellow bg-solid jgrowl-box-shadow warning-'+classId(msg)
        }, options);

        $.jGrowl(msg, options);
    });
};

notify.warn = notify.warning;

notify.error = function (msg, options) {
    _launch(function() {
        if ($('.error-'+classId(msg)).length) {
            return;
        }

        options = $.extend({
            header: '<i class="fa fa-times-circle"></i> ERROR',
            /*sticky: true ,*/
            life: 10000,
            theme: 'bg-red bg-solid jgrowl-box-shadow error-'+classId(msg)
        }, options);

        $.jGrowl(msg, options);
    });
};

notify.success = function (msg, options) {
    _launch(function() {
        if ($('.success-'+classId(msg)).length) {
            return;
        }

        options = $.extend({
            header: '<i class="fa fa-check"></i> SUCCESS',
            /*sticky: true ,*/
            life: 10000,
            theme: 'bg-green bg-solid jgrowl-box-shadow success-'+classId(msg)
        }, options);

        $.jGrowl(msg, options);
    });
};

notify.gray = function (msg, options) {
    _launch(function() {
        if ($('.gray-'+classId(msg)).length) {
            return;
        }

        options = $.extend({
            header: '',
            /*sticky: true ,*/
            life: 10000,
            theme: 'bg-gray bg-solid jgrowl-box-shadow gray-'+classId(msg)
        }, options);

        $.jGrowl(msg, options);
    });
};

notify.confirm = function (msg, options) {
    var deferred = notify.deferred = $q.defer();
    notify.lastElement = document.activeElement;
    $('#confirm-modal').modal('show');
    $('#confirm-message').html(msg);

    return deferred.promise;
};

notify.block = function (msg, options) {
    $.blockUI({
        message: msg,
        css: {
            border: 'none',
            padding: '15px',
            backgroundColor: '#000',
            '-webkit-border-radius': '10px',
            '-moz-border-radius': '10px',
            'font-size': '18px',
            opacity: .7,
            color: '#fff',
            'z-index': 1050,
        },
        overlayCSS:  {
            opacity: 0.5,
            'z-index': 3000
        },
    });
};

$(function (argument) {
    $('#confirm-btn').click(function () {
        notify.deferred.resolve(true);
        $('#confirm-modal').modal('hide');
    });
    $('#confirm-modal').on('shown.bs.modal', function (e) {
        $('#confirm-btn').focus();
    });
    $('#confirm-modal').on('hidden.bs.modal', function (e) {
        var $button = $(e.relatedTarget);

        notify.deferred.reject(false);

        notify.lastElement.focus();
    });
});

module.exports = notify;
