$(function () {
    $('.sidebar-toggle').click(function(event) {
        event.preventDefault();
        $.ajax(window.APP.base_url+'/sidebar-state', {
            data: {
                collapse: $('body').hasClass('sidebar-collapse') ? 0 : 1,
            }
        });
    });

    $('body').on('expanded.pushMenu collapsed.pushMenu', function() {
        // Add delay to trigger code only after the pushMenu animation completes
        setTimeout(function() {
            adjustFormBottomButtons();
        }, 350);
    });

    function adjustFormBottomButtons() {
        var w = $('body').hasClass('sidebar-collapse') ? '95%' : '81%';
        $('.form-bottom-buttons').css('width', w);
    }

    adjustFormBottomButtons();
});