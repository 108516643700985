var $      = window.jQuery;

$(function () {
    $('input.table-select-all').on('ifChecked', function () {
        $(this).closest('table').find('input.row-select').each(function () {
            $(this).iCheck('check');
        });
    });

    $('input.table-select-all').on('ifUnchecked', function () {
        $(this).closest('table').find('input.row-select').each(function () {
            $(this).iCheck('uncheck');
        });
    });
});
